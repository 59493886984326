import styled from "styled-components";
import { Rem } from "../../../commons/Theme";

export const StyledLateralTextContainer = styled.div`
  height: 100%;
  padding: ${Rem(10)};
  padding-right: ${Rem(30)};
  > a {
    text-decoration: none;
  }
`;

export const DiscountCode = styled.div``;

export const ShareContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 65%;
`;
